<template>
  <div class="table-container">
    <div class="table-wrapper" @click.stop="showModal(tableName)">
      <img
        v-if="tableData?.has_vegetarian"
        class="broccoli"
        src="../assets/icon/broccoli.png"
        alt="broccoli"
      />
      <div class="table">
        <div>{{ tableName }}</div>
      </div>
      <div
        v-for="chairIndex in 10"
        :key="chairIndex"
        :class="
          'chair chair__' +
          chairIndex +
          (chairIndex <= (tableData?.total_checkin || 0) ? ' checked-in' : '')
        "
      >
        <div class="chair-handler-container">
          <span class="chair-handler"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableName: {
      type: String,
      required: true
    },
    tableData: {
      type: Object,
      default: null
    },
    showModal: {
      type: Function,
      required: true
    }
  }
}
</script>

<style lang="sass" scoped>
$border-yellow: #f19506
$chair-yellow: #fee34e
$table-yellow: #ffcf12

.broccoli
  position: absolute
  width: 25%
  height: 25%
  left: 50%
  top: 6%
  transform: translateX(-50%)
  z-index: 3
  // opacity: 1
  // border: 1px solid green
  // border-radius: 999px
  // background-color: white
  // padding: 1px

.table-container
  width: fit-content
  padding: 10px
  @media (min-width: 1081px)
    padding: 5px
    scale: 0.75
  .table-wrapper
    position: relative
    width: fit-content
    cursor: pointer
    .table
      position: relative
      border: 1px solid $border-yellow
      border-radius: 100%
      min-width: 50px
      aspect-ratio: 1 / 1
      background: $table-yellow
      color: black
      z-index: 2
      display: flex
      justify-content: center
      align-items: center
    .chair
      position: absolute
      border: 1px solid $border-yellow
      width: 13px
      height: 13px
      z-index: 1
      background: $chair-yellow
      .chair-handler-container
        position: relative
        .chair-handler
          position: absolute
          width: 11px
          height: 2px
          top: 0
          background: $chair-yellow
          border-bottom: 1px solid #f19506
      &.checked-in
        background: red
        .chair-handler
          background: red
      &__1
        top: -18%
        left: 50%
        transform: translateX(-50%)
      &__2
        top: -7%
        left: 72%
        transform: rotate(40deg)
      &__3
        top: 20%
        left: 90%
        transform: rotate(70deg)
      &__4
        top: 52%
        left: 90%
        transform: rotate(110deg)
      &__5
        top: 80%
        left: 72%
        transform: rotate(140deg)
      &__6
        top: 95%
        left: 50%
        transform: translateX(-50%) rotate(180deg)
      &__7
        top: 80%
        left: 2%
        transform: rotate(-140deg)
      &__8
        top: 52%
        left: -15%
        transform: rotate(-110deg)
      &__9
        top: 20%
        left: -15%
        transform: rotate(-70deg)
      &__10
        top: -7%
        left: 2%
        transform: rotate(-40deg)
      &.chair-extra
        &__1
          top: -18%
          left: 20%
          transform: rotate(-20deg)
        &__2
          top: -18%
          right: 20%
          transform: rotate(20deg)
        &__3
          top: 0%
          right: -7%
          transform: rotate(60deg)
        &__4
          top: 30%
          right: -18%
          transform: rotate(85deg)
        &__5
          bottom: 14%
          right: -13%
          transform: rotate(120deg)
        &__6
          bottom: -10%
          right: 6%
          transform: rotate(150deg)
        &__7
          bottom: -18%
          left: 50%
          transform: translateX(-50%) rotate(-180deg)
        &__8
          bottom: -10%
          left: 6%
          transform: rotate(-150deg)
        &__9
          bottom: 14%
          left: -15%
          transform: rotate(-120deg)
        &__10
          top: 30%
          left: -18%
          transform: rotate(-85deg)
        &__11
          top: 0%
          left: -7%
          transform: rotate(-60deg)
</style>
