<template>
  <div class="bg-blueGray-800 h-screen">
    <main>
      <div class="container relative mx-auto">
        <div class="user-detail-container">
          <div class="qr-code-container" v-if="identityCode">
            <img class="mx-auto mb-8" :src="domain + identityCode" />
          </div>
          <table v-if="!errorMsg">
            <tr>
              <td class="label-column">Full Name</td>
              <td class="colon-column">:</td>
              <td class="value-column">{{ fullName }}</td>
            </tr>
            <tr>
              <td class="label-column">Company Name</td>
              <td class="colon-column">:</td>
              <td class="value-column">{{ companyName }}</td>
            </tr>
            <!--<tr>
              <td class="label-column">Table Number</td>
              <td class="colon-column">:</td>
              <td class="value-column">{{ tableName }}</td>
            </tr>-->
          </table>
          <div v-else>
            <p>{{ errorMsg }}</p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios"

export default {
  data() {
    return {
      fullName: "",
      companyName: "",
      tableName: "",
      identityCode: "",
      errorMsg: ""
    }
  },
  computed: {
    domain() {
      return process.env.VUE_APP_API_BASE_DOMAIN
    }
  },
  methods: {
    // call api to get
    async checkIn() {
      try {
        const res = await axios.get(
          process.env.VUE_APP_API_ENDPOINT +
            "/view-code-info/" +
            this.$route.params.id
        )
        if (res.status === 200) {
          this.companyName = res.data.company_name
          this.fullName = res.data.full_name
          this.tableName = res.data.table_name
          this.identityCode = res.data.identity_code
        }
      } catch (error) {
        console.log(error)
        this.errorMsg =
          error.response.data.message ||
          "Something went wrong. Please try again."
      }
    }
  },
  mounted() {
    this.checkIn()
  }
}
</script>

<style lang="sass" scoped>
main
  padding: 50px 20px
  .title
    text-align: center
    color: white
    padding-bottom: 30px
    h1
      font-size: 40px
      font-weight: bold
  .user-detail-container
    margin: auto
    max-width: 400px
    border-radius: 10px
    border: 5px solid white
    padding: 30px
    color: black
    .qr-code-container
      img
        width: 80%

        table
    width: 100%
    table-layout: fixed // Ensures fixed column widths
    border-collapse: collapse

  td
    word-wrap: break-word // Allows long words to break and wrap to the next line
    white-space: normal // Allows the text to wrap onto multiple lines
    padding: 8px
    vertical-align: top

  .label-column
    width: 37% // Adjust this percentage as needed to control label width
    font-weight: bold

  .colon-column
    width: 3% // Ensures the colon has a fixed width
    text-align: center

  .value-column
    width: 60% // Adjust this percentage to control value column width
</style>
