<template>
  <div
    :class="{
      'outer-container': true,
      'h-screen': isHeightOver && isDesktop
    }"
  >
    <div class="inner-container">
      <div class="logo-container">
        <img src="../../assets/img/cny-2025/ntt-logo.png" alt="ntt-logo" />
      </div>
      <img
        class="vertical-text vertical-text__left"
        src="../../assets/img/cny-2025/left-vertical-text.png"
        alt="蛇舞今朝"
      />
      <img
        class="vertical-text vertical-text__right"
        src="../../assets/img/cny-2025/right-vertical-text.png"
        alt="蛇形天下"
      />
      <img
        class="ntt-icon desktop"
        src="../../assets/img/cny-2025/n-logo.png"
        alt="ntt-icon"
      />
      <img
        class="search-icon"
        src="../../assets/icon/search.png"
        alt="search-icon"
        @click.stop="showSearchModal"
      />
      <img
        class="full-screen-icon"
        src="../../assets/icon/maximize.png"
        alt="full-screen-icon"
        @click.stop="displayFullScreen"
      />
      <!-- Table Layout -->
      <div
        class="table-layout-container desktop"
        :style="{ transform: `scale(${width})` }"
      >
        <div class="table-layout">
          <div class="main-container">
            <div class="stage">
              Stage
              <img
                class="stairs"
                src="../../assets/img/cny-2025/stairs.png"
                width="20"
                height="67"
                alt="stairs"
              />
            </div>
            <div class="seating">
              <div class="top">
                <div class="top-left row">
                  <Table
                    v-for="tableName in topLeft"
                    :key="tableName"
                    :tableName="tableName"
                    :tableData="getTableData(tableName)"
                    :showModal="showModal"
                  />
                </div>
                <div class="top-center">
                  <div class="left">
                    <div class="the-balvenie-bar" />
                    <div>Hendrick's Gin Cart</div>
                  </div>
                  <div class="center">
                    <div class="hendricks-gin-cart" />
                    <div>Penfolds Wine Bar</div>
                  </div>
                  <div class="right">
                    <div class="penfolds-wine-bar" />
                    <div>The Balvenie Bar</div>
                  </div>
                </div>
                <div class="top-right row">
                  <Table
                    v-for="tableName in topRight"
                    :key="tableName"
                    :tableName="tableName"
                    :tableData="getTableData(tableName)"
                    :showModal="showModal"
                  />
                </div>
              </div>
              <div class="center">
                <div class="acrobatic-lion-dance-area">
                  Acrobatic Lion Dance Area
                </div>
              </div>
              <div class="bottom">
                <div class="row">
                  <Table
                    v-for="tableName in bottomRow1"
                    :key="tableName"
                    :tableName="tableName"
                    :tableData="getTableData(tableName)"
                    :showModal="showModal"
                  />
                </div>
                <div class="row">
                  <Table
                    v-for="tableName in bottomRow2"
                    :key="tableName"
                    :tableName="tableName"
                    :tableData="getTableData(tableName)"
                    :showModal="showModal"
                  />
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                  "
                >
                  <div
                    style="display: flex; justify-content: center; width: 48.5%"
                  >
                    <div class="row">
                      <div class="row" style="display: flex; width: 100%">
                        <Table
                          v-for="tableName in bottomRow3Left"
                          :key="tableName"
                          :tableName="tableName"
                          :tableData="getTableData(tableName)"
                          :showModal="showModal"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style="display: flex; justify-content: center; width: 40%"
                  >
                    <div class="row" style="display: flex; width: 100%">
                      <Table
                        v-for="tableName in bottomRow3Right"
                        :key="tableName"
                        :tableName="tableName"
                        :tableData="getTableData(tableName)"
                        :showModal="showModal"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right-container">
            <div class="right-container__inner">
              <div>Restaurant<br />Drink<br />Station<br /></div>
              <div class="restaurant-drink-station" />
            </div>
            <div class="right-container__inner">
              <div>AV<br />Control<br />Booth<br /></div>
              <img
                src="../../assets/img/cny-2025/av-control-booth.png"
                class="av-control-booth"
                alt="av-control-booth"
                width="30"
              />
            </div>
          </div>
          <div class="arrow-up" />
          <div class="door door__left">
            <img
              src="../../assets/img/cny-2025/door.png"
              width="50"
              alt="door"
            />
            <img
              style="position: absolute; top: 0"
              src="../../assets/img/cny-2025/no-entry-door.png"
              width="50"
              alt="no-entry-door"
            />
          </div>
          <div class="door door__center">
            <img
              src="../../assets/img/cny-2025/door.png"
              width="50"
              alt="door"
            />
          </div>
          <div class="door door__right">
            <img
              src="../../assets/img/cny-2025/door.png"
              width="50"
              alt="door"
            />
            <img
              style="position: absolute; top: 0"
              src="../../assets/img/cny-2025/no-entry-door.png"
              width="50"
              alt="no-entry-door"
            />
          </div>
        </div>
      </div>
      <!-- Table Layout - Mobile -->
      <div
        class="table-layout-container mobile"
        :style="{ transform: `scale(${width})` }"
      >
        <div class="table-layout">
          <div class="main-container">
            <div class="stage">
              Stage
              <img
                class="stairs"
                src="../../assets/img/cny-2025/stairs-mobile.png"
                width="67"
                height="20"
                alt="stairs"
              />
            </div>
            <div class="seating">
              <div class="left">
                <div class="top-left">
                  <Table
                    v-for="tableName in mobileTopLeft"
                    :key="tableName"
                    :tableName="tableName"
                    :tableData="getTableData(tableName)"
                    :showModal="showModal"
                  />
                </div>
                <div class="middle-left">
                  <div class="middle-left__inner">
                    <Table
                      v-for="tableName in mobileMiddleLeft"
                      :key="tableName"
                      :tableName="tableName"
                      :tableData="getTableData(tableName)"
                      :showModal="showModal"
                    />
                  </div>
                </div>
                <div class="bottom-left">
                  <Table
                    v-for="tableName in mobileBottomLeft"
                    :key="tableName"
                    :tableName="tableName"
                    :tableData="getTableData(tableName)"
                    :showModal="showModal"
                  />
                </div>
              </div>
              <div class="center">
                <div class="acrobatic-lion-dance-area">
                  Acrobatic Lion Dance Area
                </div>
              </div>
              <div class="right">
                <div class="top-right">
                  <Table
                    v-for="tableName in mobileTopRight"
                    :key="tableName"
                    :tableName="tableName"
                    :tableData="getTableData(tableName)"
                    :showModal="showModal"
                  />
                </div>
                <div class="middle-right">
                  <div
                    style="
                      display: flex;
                      gap: 0.5rem;
                      justify-content: flex-end;
                      align-items: center;
                      text-align: right;
                    "
                  >
                    <div>Hendrick's Gin Cart</div>
                    <div class="the-balvenie-bar" />
                  </div>
                  <div
                    style="
                      display: flex;
                      gap: 0.5rem;
                      justify-content: flex-end;
                      align-items: center;
                      text-align: right;
                    "
                  >
                    <div>Penfolds Wine Bar</div>
                    <div class="hendricks-gin-cart" />
                  </div>
                  <div
                    style="
                      display: flex;
                      gap: 0.5rem;
                      justify-content: flex-end;
                      align-items: center;
                      text-align: right;
                    "
                  >
                    <div>The Balvenie Bar</div>
                    <div class="penfolds-wine-bar" />
                  </div>
                </div>
                <div class="bottom-right">
                  <Table
                    v-for="tableName in mobileBottomRight"
                    :key="tableName"
                    :tableName="tableName"
                    :tableData="getTableData(tableName)"
                    :showModal="showModal"
                  />
                </div>
              </div>
            </div>
            <div class="bottom-container">
              <div class="bottom-container__inner">
                <div>AV<br />Control<br />Booth<br /></div>
                <img
                  src="../../assets/img/cny-2025/av-control-booth-mobile.png"
                  class="av-control-booth"
                  alt="av-control-booth"
                  width="30"
                />
              </div>
              <div class="bottom-container__inner">
                <div>Restaurant<br />Drink<br />Station<br /></div>
                <div class="restaurant-drink-station" />
              </div>
            </div>
          </div>
          <div class="arrow-right" />
          <div class="door door__top">
            <img
              src="../../assets/img/cny-2025/door-mobile.png"
              height="50"
              alt="door"
            />
            <img
              style="position: absolute; top: 0; right: 0"
              src="../../assets/img/cny-2025/no-entry-door-mobile.png"
              height="50"
              alt="no-entry-door"
            />
          </div>
          <div class="door door__middle">
            <img
              src="../../assets/img/cny-2025/door-mobile.png"
              height="50"
              alt="door"
            />
          </div>
          <div class="door door__bottom">
            <img
              src="../../assets/img/cny-2025/door-mobile.png"
              height="50"
              alt="door"
            />
            <img
              style="position: absolute; top: 0; right: 0"
              src="../../assets/img/cny-2025/no-entry-door-mobile.png"
              height="50"
              alt="no-entry-door"
            />
          </div>
        </div>
        <!-- <div class="bottom-text-container">
          <img
            class="bottom-text mobile"
            src="../../assets/img/cny-2025/bottom-text-mobile.png"
            alt="a-snazzy-chinese-new-year-celebration"
          />
        </div>
        <img
          class="ntt-icon mobile"
          src="../../assets/img/cny-2025/n-logo.png"
          alt="ntt-icon"
        /> -->
      </div>
      <div class="bottom-text-container">
        <div class="bottom-text">
          <img
            class="desktop bottom-text-img"
            src="../../assets/img/cny-2025/bottom-text.png"
            alt="a-snazzy-chinese-new-year-celebration"
          />
          <img
            class="ntt-icon desktop"
            src="../../assets/img/cny-2025/n-logo.png"
            alt="ntt-icon"
          />
        </div>
      </div>
    </div>
    <!-- Popup Modal -->
    <div v-show="isShowModal" class="modal-overlay">
      <div class="modal-container">
        <div class="modal" ref="modal">
          <div class="modal__header">
            <div class="flex justify-between">
              <div class="font-bold">{{ selectedTableName }}</div>
              <div class="close-icon" @click="hideModal()">&#10006;</div>
            </div>
          </div>
          <div v-if="tableGuestList.length > 0" class="modal__body">
            <div class="checked-in-container">
              <div class="font-bold">Checked-In Guest&#40;s&#41;</div>
              <ol v-if="hasCheckedInGuests">
                <li
                  v-for="(guest, index) in tableGuestList.filter(
                    (guest) => guest.checkin_status === 1
                  )"
                  :key="index"
                >
                  {{ guest.full_name }}
                  <img
                    v-if="guest.is_vegetarian"
                    class="broccoli"
                    src="../../assets/icon/broccoli.png"
                    alt="broccoli"
                    width="15"
                    height="15"
                  />
                </li>
              </ol>
              <p v-else class="italic text-sm">No guests checked-in yet</p>
            </div>
            <div class="not-checked-in-container">
              <div class="font-bold">Pending Guests&#40;s&#41;</div>
              <ol v-if="hasPendingGuests">
                <li
                  v-for="(guest, index) in tableGuestList.filter(
                    (guest) => guest.checkin_status === 0
                  )"
                  :key="index"
                >
                  {{ guest.full_name }}
                  <img
                    v-if="guest.is_vegetarian"
                    class="broccoli"
                    src="../../assets/icon/broccoli.png"
                    alt="broccoli"
                    width="15"
                    height="15"
                  />
                </li>
              </ol>
              <p v-else class="italic text-sm">No guests available</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Search Popup Modal -->
    <div v-show="isShowSearchModal" class="modal-overlay">
      <div class="modal-container">
        <div class="modal" ref="searchModal">
          <div class="modal__header">
            <div class="modal__header__container">
              <div class="search-field">
                <input
                  type="text"
                  v-model="searchQuery"
                  placeholder="Search Guest(s)"
                  @input="handleSearchInput"
                />
              </div>
              <div class="close-icon" @click="hideSearchModal">&#10006;</div>
            </div>
          </div>
          <div class="modal__body">
            <div v-if="searchGuestResult.length > 0" class="modal__body__inner">
              <div class="checked-in-container">
                <div class="font-bold">Checked-In Guest&#40;s&#41;</div>
                <ol v-if="searchHasCheckedInGuests">
                  <li
                    v-for="(guest, index) in searchGuestResult.filter(
                      (guest) => guest.checkin_status === 1
                    )"
                    :key="index"
                  >
                    {{ guest.full_name }}
                    <img
                      v-if="guest.is_vegetarian"
                      class="broccoli"
                      src="../../assets/icon/broccoli.png"
                      alt="broccoli"
                      width="15"
                      height="15"
                    />
                  </li>
                </ol>
                <p v-else class="italic text-sm">No guests checked-in yet</p>
              </div>
              <div class="not-checked-in-container">
                <div class="font-bold">Pending Guests&#40;s&#41;</div>
                <ol v-if="searchHasPendingGuests">
                  <li
                    v-for="(guest, index) in searchGuestResult.filter(
                      (guest) => guest.checkin_status === 0
                    )"
                    :key="index"
                  >
                    {{ guest.full_name }}
                    <img
                      v-if="guest.is_vegetarian"
                      class="broccoli"
                      src="../../assets/icon/broccoli.png"
                      alt="broccoli"
                      width="15"
                      height="15"
                    />
                  </li>
                </ol>
                <p v-else class="italic text-sm">No guests available</p>
              </div>
            </div>
            <div v-else-if="searchQuery && searchGuestResult.length === 0">
              <p>No results found</p>
            </div>
            <div v-else>
              <p class="italic">Guest(s) data will appear here</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import Table from "../../components/Table.vue"

export default {
  components: {
    Table
  },
  data() {
    return {
      width: 0,
      tableData: [],
      tableGuestList: [],
      isShowModal: false,
      isShowSearchModal: false,
      selectedTableName: "",
      isHeightOver: false,
      isDesktop: false,
      intervalId: null,
      searchQuery: "",
      searchGuestResult: [],
      topLeft: [
        "6",
        "7",
        "18",
        "19",
        "5",
        "8",
        "17",
        "20",
        "3A",
        "9",
        "16",
        "21"
      ],
      topRight: [
        "33A",
        "35",
        "46",
        "47",
        "58",
        "33",
        "36",
        "45",
        "48",
        "57",
        "32",
        "37",
        "43A",
        "49",
        "56"
      ],
      bottomRow1: [
        "3",
        "10",
        "15",
        "22",
        "27",
        "28",
        "31",
        "38",
        "43",
        "50",
        "55"
      ],
      bottomRow2: [
        "2",
        "11",
        "13A",
        "23",
        "26",
        "29",
        "30",
        "39",
        "42",
        "51",
        "53A"
      ],
      bottomRow3Left: ["1", "12", "13", "23A", "25"],
      bottomRow3Right: ["40", "41", "52", "53"],
      mobileTopLeft: [
        "1",
        "2",
        "3",
        "12",
        "11",
        "10",
        "13",
        "13A",
        "15",
        "23A",
        "23",
        "22",
        "25",
        "26",
        "27"
      ],
      mobileMiddleLeft: ["29", "28", "30", "31"],
      mobileBottomLeft: [
        "40",
        "39",
        "38",
        "41",
        "42",
        "43",
        "52",
        "51",
        "50",
        "53",
        "53A",
        "55"
      ],
      mobileTopRight: [
        "3A",
        "5",
        "6",
        "9",
        "8",
        "7",
        "16",
        "17",
        "18",
        "21",
        "20",
        "19"
      ],
      mobileBottomRight: [
        "32",
        "33",
        "33A",
        "37",
        "36",
        "35",
        "43A",
        "45",
        "46",
        "49",
        "48",
        "47",
        "56",
        "57",
        "58"
      ]
    }
  },
  computed: {
    hasCheckedInGuests() {
      return this.tableGuestList.some((guest) => guest.checkin_status === 1)
    },
    hasPendingGuests() {
      return this.tableGuestList.some((guest) => guest.checkin_status === 0)
    },
    searchHasCheckedInGuests() {
      return this.searchGuestResult.some((guest) => guest.checkin_status === 1)
    },
    searchHasPendingGuests() {
      return this.searchGuestResult.some((guest) => guest.checkin_status === 0)
    }
  },
  methods: {
    async fetchTableLayout() {
      try {
        const res = await axios.get(
          process.env.VUE_APP_API_ENDPOINT + "/table-layout"
        )
        this.tableData = res.data.data
      } catch (error) {
        console.error(error)
      }
    },
    startFetchingTableLayout() {
      this.fetchTableLayout()

      // Set interval to fetch every 10 seconds
      this.intervalId = setInterval(() => {
        this.fetchTableLayout()
      }, 10000)
    },
    onResize() {
      if (window.innerWidth > 1080) {
        this.isDesktop = true
      } else {
        this.isDesktop = false
      }
      if (window.innerWidth <= 1400 && window.innerWidth > 1080) {
        this.width = (window.innerWidth * (1200 / 1400)) / 1200
      } else if (window.innerWidth <= 1080) {
        this.width = (window.innerWidth * (1200 / 1080)) / 1200
      } else {
        this.width = 1
      }
      this.isHeightOver = window.innerHeight >= 921
    },
    async showModal(tableName) {
      this.selectedTableName = tableName
      this.isShowModal = true
      try {
        const res = await axios.get(
          process.env.VUE_APP_API_ENDPOINT + "/table-guest-list/" + tableName
        )
        this.tableGuestList = res.data
      } catch (error) {
        console.error(error)
      }
    },
    hideModal() {
      this.isShowModal = false
    },
    showSearchModal() {
      this.isShowSearchModal = true
    },
    hideSearchModal() {
      this.isShowSearchModal = false
    },
    displayFullScreen() {
      const body = document.querySelector("body")

      if (!document.fullscreenElement) {
        // Enable fullscreen mode
        if (body.requestFullscreen) {
          body.requestFullscreen().catch((err) => {
            console.error(`Failed to enter fullscreen mode: ${err.message}`)
          })
        } else {
          console.warn("Fullscreen API is not supported in this browser.")
        }
      } else {
        // Disable fullscreen mode
        if (document.exitFullscreen) {
          document.exitFullscreen().catch((err) => {
            console.error(`Failed to exit fullscreen mode: ${err.message}`)
          })
        } else {
          console.warn("Fullscreen API is not supported in this browser.")
        }
      }
    },
    handleClickOutside(event) {
      if (this.isShowModal) {
        const modal = this.$refs.modal
        if (modal && !modal.contains(event.target)) {
          this.hideModal()
        }
      }
    },
    handleClickOutsideSearchModal(event) {
      if (this.isShowSearchModal) {
        const searchModal = this.$refs.searchModal
        if (searchModal && !searchModal.contains(event.target)) {
          this.hideSearchModal()
        }
      }
    },
    async handleSearchInput() {
      try {
        const res = await axios.get(
          process.env.VUE_APP_API_ENDPOINT +
            "/table-guest-data?search=" +
            this.searchQuery
        )
        this.searchGuestResult = res.data
      } catch (error) {
        console.error(error)
      }
    },
    getTableData(tableName) {
      return (
        this.tableData.find((table) => table.table_name === tableName) || null
      )
    }
  },
  async mounted() {
    this.onResize()
    window.addEventListener("click", this.handleClickOutside)
    window.addEventListener("click", this.handleClickOutsideSearchModal)
    window.addEventListener("resize", this.onResize)
    window.addEventListener("orientationchange", this.onResize)

    this.startFetchingTableLayout()
  },
  beforeUnmount() {
    clearInterval(this.intervalId)
    window.removeEventListener("click", this.handleClickOutside)
    window.removeEventListener("click", this.handleClickOutsideSearchModal)
    window.removeEventListener("resize", this.onResize)
    window.removeEventListener("orientationchange", this.onResize)
  }
}
</script>

<style lang="sass" scoped>
$primary: white
$chair-yellow: #fee34e
$table-yellow: #ffcf12
$border-yellow: #f19506
$text-yellow: #fcdc02
$blue: #238ccc

@media (max-width: 1080px)
  .desktop
    display: none

@media (min-width: 1081px)
  .mobile
    display: none

.outer-container
  width: 100%
  background: $primary
  .inner-container
    position: relative
    background-size: cover
    background-repeat: no-repeat
    background-position: center
    overflow-x: hidden
    overflow-y: auto
    color: white
    line-height: 18px
    background-image: url("../../assets/img/cny-2025/bg.png")
    .vertical-text
      position: absolute
      top: 14%
      width: 8%
      max-width: 80px
      &__left
        left: 0
      &__right
        right: 0
      @media (max-width: 1080px)
        max-width: 110px
        width: 10%
        top: 4.5%
        &__left
          left: 3%
        &__right
          right: 3%
    @media (max-width: 1080px)
      background-image: url("../../assets/img/cny-2025/bg-mobile.png")
      overflow: hidden
      aspect-ratio: 1 / 1.8
      height: 100%
      padding-top: 15%
    .logo-container
      display: flex
      justify-content: center
      padding: 20px
      img
        width: 60%
        max-width: 350px
      @media (max-width: 1080px)
        display: none
    .ntt-icon
      position: absolute
      left: 100%
      bottom: 30px
      width: 20%
      max-width: 130px
      &.mobile
        bottom: 2%
        left: unset
        right: 20px
        max-width: 120px
    .bottom-text-container
      display: flex
      justify-content: center
      .bottom-text
        position: relative
        &-img
          &.desktop
            width: 100%
            max-width: 800px
            margin: 0 auto
            padding: 60px 60px 30px
        &.mobile
          position: absolute
          left: 5%
          bottom: 3%
          width: 33%
    .search-icon
      max-width: 23px
      position: absolute
      top: 10px
      right: 3%
    .full-screen-icon
      max-width: 23px
      position: absolute
      top: 10px
      left: 3%
    .table-layout-container
      position: relative
      .table-layout
        position: relative
        display: flex
        margin: auto
        font-size: 1rem
        @media (max-width: 1080px)
          font-size: 1.4rem
          line-height: 1.5rem
          margin-bottom: 20px
      .main-container
        position: relative
        display: flex
        width: 100%
        height: 100%
        .stage
          position: relative
          display: flex
          justify-content: center
          align-items: center
          width: 100px
          height: 100%
          background: linear-gradient(to right, #6C6D70, #48484A)
          padding: 70px 20px
          .stairs
            position: absolute
            left: 100%
        .seating
          width: 100%
          height: 100%
          background: linear-gradient(to right, rgba(161, 137, 100, 0.7), rgba(199, 167, 134, 0.7))
          gap: 20px
          .row
            display: flex
            justify-content: space-between
          .top
            display: flex
            justify-content: center
            gap: 1rem
            transform: translateX(0.5%)
            .row
              column-gap: 0.5rem
            .top-left, .top-right
              display: flex
              flex-wrap: wrap
              justify-content: center
            .top-left
              width: 34%
            .top-right
              transform: translateX(5%)
              width: 42%
            .top-center
              display: flex
              gap: 0.5rem
              width: 20%
              margin-top: 1rem
              font-size: 12px
              line-height: 14px
              .left, .right, .center
                display: flex
                flex-direction: column
                gap: 0.5rem
                font-size: 12px
              .left
                text-align: right
                align-items: flex-end
              .center
                text-align: center
                align-items: center
                justify-content: flex-start
              .right
                align-items: flex-start
              .the-balvenie-bar, .hendricks-gin-cart, .penfolds-wine-bar
                width: 45px
                height: 30px
                border: 1px solid white
                background-color: $blue
          .center
            display: flex
            justify-content: center
            align-items: center
            .acrobatic-lion-dance-area
              background: linear-gradient(to right, #BF1E55, #ED1E2E)
              width: 300px
              height: 130px
              display: flex
              justify-content: center
              align-items: center
              margin: 0.5rem 0
          .bottom
            display: flex
            flex-direction: column
            align-items: center
            transform: translateX(-2.5%)
            .row
              width: 100%
              gap: 0.5rem
              justify-content: center

      &.mobile
        padding: 0 120px 170px
        transform-origin: top left
        width: 1080px
        margin: 0
        .table-layout
          aspect-ratio: 1 / 2.13
          flex-direction: column
          margin: 0 4.5%
          .arrow-right
            position: absolute
            left: 1rem
            top: 50%
            transform: translateY(-50%)
            background: linear-gradient(to right, #BF1E55, #ED1E2E)
            width: 30px
            height: 30px
            clip-path: polygon(0% 50%, 30% 30%, 30% 0%, 100% 50%, 30% 100%, 30% 70%, 0% 50%)
          .door
            position: absolute
            left: -27px
            &__top
              top: 30%
            &__middle
              top: 50%
              transform: translateY(-50%)
            &__bottom
              bottom: 35%
          .main-container
            flex-direction: column
            .stage
              position: relative
              display: flex
              justify-content: center
              align-items: center
              width: 100%
              height: 100px
              background: linear-gradient(to bottom, #6C6D70, #48484A)
              .stairs
                position: absolute
                top: 99%
                left: 50%
                transform: translateX(-50%)
            .seating
              background: linear-gradient(to bottom, rgba(161, 137, 100, 0.7), rgba(199, 167, 134, 0.7))
              padding: 60px 20px
              display: flex
              gap: 50px
              .left, .right
                width: 40%
                display: flex
                flex-direction: column
                justify-content: center
                gap: 1rem
              .left
                .top-left, .middle-left, .bottom-left
                  display: flex
                  flex-wrap: wrap
                  gap: 1rem
                  justify-content: flex-end
                .middle-left
                  &__inner
                    width: 70%
                    display: flex
                    flex-wrap: wrap
                    gap: 1rem
                    justify-content: flex-end
              .right
                justify-content: flex-start
                transform: translateY(7.5%)
                .top-right, .bottom-right
                  display: flex
                  flex-wrap: wrap
                  gap: 1rem
                  justify-content: flex-start
              .center
                width: 20%
                display: flex
                justify-content: center
                align-items: center
                .acrobatic-lion-dance-area
                  background: linear-gradient(to bottom, #BF1E55, #ED1E2E)
                  height: 400px
                  width: 140px
                  text-align: center
                  padding: 0.5rem
              .right
                width: 40%
                .middle-right
                  display: flex
                  flex-direction: column
                  gap: 1rem
                  margin: 1.8rem 3rem 2rem 0
                  .the-balvenie-bar, .hendricks-gin-cart, .penfolds-wine-bar
                    height: 50px
                    width: 30px
                    border: 1px solid white
                    background-color: $blue

          .bottom-container
            display: flex
            justify-content: flex-end
            &__inner
              display: flex
              flex-direction: column
              justify-content: center
              align-items: center
              gap: 1rem
              height: 270px
              width: 220px
              text-align: center
              background: linear-gradient(to bottom, rgba(161, 137, 100, 0.9), rgba(199, 167, 134, 0.9))
              .restaurant-drink-station
                border: 1px solid white
                width: 150px
                height: 50px
                background-color: $blue
              img
                height: 50px
                width: auto
      &.desktop
        padding: 0 150px
        height: 100%
        transform-origin: top left
        width: 1400px
        margin: 0 auto

        .table-layout
          justify-content: flex-end
          aspect-ratio: 1.9 / 1
          .main-container
            .seating
              padding: 20px
          .right-container
            display: flex
            flex-direction: column
            justify-content: stretch
            width: 200px
            height: 55%
            padding: 1rem
            text-align: right
            background: linear-gradient(to right, rgba(161, 137, 100, 0.9), rgba(199, 167, 134, 0.9))
            &__inner
              height: 50%
              display: flex
              justify-content: flex-end
              align-items: center
              gap: 1rem
              .restaurant-drink-station
                border: 1px solid white
                width: 30px
                height: 100px
                background-color: $blue
              .av-control-booth
                width: 30px
          .door
            position: absolute
            top: 100%
            &__left
              left: 28%
            &__center
              left: 48%
              transform: translateX(-50%)
            &__right
              right: 37%
          .arrow-up
            position: absolute
            bottom: 0.5rem
            left: 48%
            transform: rotate(-90deg) translateY(-50%)
            background: linear-gradient(to right, #BF1E55, #ED1E2E)
            width: 30px
            height: 30px
            clip-path: polygon(0% 30%, 30% 30%, 30% 0%, 100% 50%, 30% 100%, 30% 70%, 0% 70%)

  .modal-overlay
    position: fixed
    left: 0
    right: 0
    top: 0
    bottom: 0
    background: rgba(0, 0, 0, 0.5)
    .modal-container
      display: flex
      width: 100%
      height: 100%
      justify-content: center
      align-items: center
      .modal
        background: #6C6D70
        width: 100%
        height: 100%
        max-width: 500px
        max-height: 500px
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5)
        color: white
        margin: 15px
        overflow-y: auto
        &__header
          padding: 20px
          border-bottom: 0.5px solid lightgrey
          .close-icon
            color: transparent
            text-shadow: 0 0 0 rgb(255, 255, 255)
          &__container
            display: flex
            justify-content: space-between
            width: 100%
            gap: 1rem
          .search-field
            width: 100%
            input
              background: transparent
              width: 100%
              border-color: white
              &::placeholder
                color: white
                opacity: 0.6
        &__body
          padding: 20px
          display: flex
          flex-direction: column
          gap: 30px
          &__inner
            display: flex
            flex-direction: column
            gap: 30px
          ol
            list-style-type: alpha
            li
              margin-left: 1.2rem
              .broccoli
                display: inline
</style>
