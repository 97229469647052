<template>
  <div>
    <main>
      <div style="background-color: #070f26; min-height: 100%">
        <br class="desktop-break" />
        <img
          alt="ntt-data-banner"
          :src="topBanner"
          class="mx-auto responsive-image"
        />
      </div>
      <section
        class="relative block w-full min-h-screen"
        style="background: #070f26"
      >
        <div class="flex flex-wrap justify-center">
          <div class="form-container">
            <div
              class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-b-lg bg-blueGray-200"
            >
              <form @submit.prevent="submit">
                <!--<div v-if="!isSuccess" class="flex-auto p-5 lg:p-10">
                  <h4 class="text-2xl font-semibold">Join Us!</h4>
                  <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                    Register for NTT DATA’s 2025 Chinese New Year Client &
                    Partner Appreciation Dinner
                  </p>
                  <br />
                  <div class="flex flex-col" style="gap: 1.5rem">
                    <--<div class="relative row mt-4">-->
                      <!-- Full Name -->
                      <!--<label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        Full Name <span style="color: red">*</span>
                      </label>
                      <input
                        type="full-name"
                        v-model="form.fullName"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Full Name"
                        :readonly="isReadOnly"
                        required
                      />--
                      <-- First Name --
                         <div class="relative col mr-2">
                          <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="first-name"
                          >
                            First Name <span style="color:red">*</span>
                          </label>
                          <input
                            type="text"
                            v-model="form.firstName"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="First Name"
                            :readonly="isReadOnly"
                            required
                          />
                          <div v-if="errors.firstName" class="error-msg">
                            {{ errors.firstName }}
                          </div>
                        </div> 
                        <-- Last Name --
                        <div class="relative col mr-2">
                          <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="last-name"
                          >
                            Last Name <span style="color:red">*</span>
                          </label>
                          <input
                            type="text"
                            v-model="form.lastName"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Last Name / Surname / Family Name"
                            :readonly="isReadOnly"
                            required
                          />
                          <div v-if="errors.lastName" class="error-msg">
                            {{ errors.lastName }}
                          </div>
                        </div>
                    <--</div>--
                    <-- Job Title --
                    <div class="relative col mr-2">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="job-title"
                      >
                        Job Title <span style="color:red">*</span>
                      </label>
                      <input
                        type="text"
                        v-model="form.jobTitle"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Job Title"
                        required
                      />
                      <div v-if="errors.jobTitle" class="error-msg">
                        {{ errors.jobTitle }}
                      </div>
                    </div>
                    <-- Company Name --
                    <div class="relative row mt-4">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="company-name"
                      >
                        Company Name
                        <span style="color: red">*</span>
                      </label>
                      <input
                        type="company-name"
                        v-model="form.companyName"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Company Name"
                        :readonly="isReadOnly"
                        required
                      />
                      <div v-if="errors.companyName" class="error-msg">
                        {{ errors.companyName }}
                      </div>
                    </div>
                    <-- Email --
                    <div class="relative row mt-4">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Business Email Address <span style="color: red">*</span>
                      </label>
                      <input
                        type="email"
                        v-model="form.email"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Business Email Address"
                        required
                      />
                      <div v-if="errors.email" class="error-msg">
                        {{ errors.email }}
                      </div>
                    </div>
                    <div class="flex row mt-4">
                      <-- Mobile Number --
                      <div class="relative row w-full">
                        <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="mobile-number"
                        >
                          Contact Number <span style="color: red">*</span>
                        </label>
                        <div class="flex">
                          <div class="relative">
                            <div
                              class="country-code-dropdown appearance-none border-0 px-3 py-3 text-blueGray-600 bg-white rounded-l text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                              @click="toggleDropdown"
                              ref="countryCodeDropdown"
                            >
                              +{{ form.countryCode }}
                            </div>
                            <div
                              v-show="isDropdownVisible"
                              class="custom-dropdown"
                            >
                              <div
                                v-for="countryCode in countryCodes"
                                :key="countryCode.id"
                                @click="selectCountry(countryCode)"
                                class="country-code-item"
                              >
                                {{ countryCode.nicename }} (+{{
                                  countryCode.phonecode
                                }})
                              </div>
                            </div>
                          </div>
                          <input
                            type="text"
                            v-model="form.mobileNumber"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Mobile Number"
                            required
                          />
                        </div>
                        <div v-if="errors.mobileNumber" class="error-msg">
                          {{ errors.mobileNumber }}
                        </div>
                      </div>
                    </div>
                    <-- Interested --
                    <--<div class="relative row mt-4">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="interested"
                      >
                        Will you attend? <span style="color: red">*</span>
                      </label>
                      <div class="flex flex-col text-sm">
                        <label for="yes">
                          <input
                            type="radio"
                            v-model="form.interested"
                            id="yes"
                            :value="'true'"
                            class="mr-2"
                            checked
                          />
                          Yes, I will
                        </label>
                        <label for="no">
                          <input
                            type="radio"
                            v-model="form.interested"
                            id="no"
                            :value="'false'"
                            class="mr-2"
                          />
                          No, unable to attend
                        </label>
                        <label for="no1">
                          <input
                            type="radio"
                            v-model="form.interested"
                            id="no1"
                            :value="'false1'"
                            class="mr-2"
                          />
                          No, but will send a representative
                        </label>
                      </div>
                    </div>--
                    <-- Vegetarian --
                    <div class="relative row w-full">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="preferred-session"
                      >
                        Are you a vegetarian? <span style="color: red">*</span>
                      </label>
                      <div class="flex flex-col text-sm">
                        <label for="yes">
                          <input
                            type="radio"
                            v-model="form.vegetarian"
                            id="yes"
                            :value="true"
                            class="mr-2"
                            required
                          />
                          Yes
                        </label>
                        <label for="no">
                          <input
                            type="radio"
                            v-model="form.vegetarian"
                            id="no"
                            :value="false"
                            class="mr-2"
                            required
                          />
                          No
                        </label>
                      </div>
                      <div v-if="errors.vegetarian" class="error-msg">
                        {{ errors.vegetarian }}
                      </div>
                    </div>
                    <-- Representative --
                    <div class="relative row mt-4">
                      <--<label
                        class="block uppercase text-blueGray-600 text-xm font-bold mb-2"
                        htmlFor="title"
                      >
                        Representative Information: (optional)
                        <br /><br />
                      </label>-->
                      <!--<div class="flex row mt-4">
                        <-- First Name --
                        <div class="relative col mr-2">
                          <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="first-name"
                          >
                            First Name
                          </label>
                          <input
                            type="text"
                            v-model="form.repFirstName"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="First Name"
                          />
                          <div v-if="errors.repFirstName" class="error-msg">
                            {{ errors.repFirstName }}
                          </div>
                        </div>
                        <-- Last Name --
                        <div class="relative col ml-2">
                          <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="last-name"
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            v-model="form.repLastName"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Last Name / Surname / Family Name"
                          />
                          <div v-if="errors.repLastName" class="error-msg">
                            {{ errors.repLastName }}
                          </div>
                          <div v-if="errors.mobileNumber" class="error-msg">
                            {{ errors.mobileNumber }}
                          </div>
                        </div>
                      </div>-->
                      <!--<div class="relative row mt-4">
                        <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="email"
                        >
                          Email Address
                        </label>
                        <input
                          type="email"
                          v-model="form.repEmail"
                          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Business Email Address"
                        />
                        <div v-if="errors.repEmail" class="error-msg">
                          {{ errors.repEmail }}
                        </div>
                      </div>-->
                      <!--<div class="flex row mt-4">
                        <-- Mobile Number --
                        <div class="relative row w-full">
                          <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="mobile-number"
                          >
                            Contact Number
                          </label>
                          <div class="flex">
                            <div class="relative">
                              <div
                                class="country-code-dropdown appearance-none border-0 px-3 py-3 text-blueGray-600 bg-white rounded-l text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                                @click="repToggleDropdown"
                                ref="repCountryCodeDropdown"
                              >
                                +{{ form.repCountryCode }}
                              </div>
                              <div
                                v-show="isRepDropdownVisible"
                                class="custom-dropdown"
                              >
                                <div
                                  v-for="countryCode in repCountryCodes"
                                  :key="countryCode.id"
                                  @click="selectCountryRep(countryCode)"
                                  class="country-code-item"
                                >
                                  {{ countryCode.nicename }} (+{{
                                    countryCode.phonecode
                                  }})
                                </div>
                              </div>
                            </div>
                            <input
                              type="text"
                              v-model="form.repMobileNumber"
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Mobile Number"
                            />
                          </div>
                          <div v-if="errors.repMobileNumber" class="error-msg">
                            {{ errors.repMobileNumber }}
                          </div>
                        </div>
                      </div>--
                      <br />
                      <-- Consent --
                      <div class="relative w-full my-2">
                        <input
                          type="checkbox"
                          id="consent"
                          :value="true"
                          v-model="form.consent"
                          class="mr-2"
                          required
                        />
                        <label for="consent"
                          >By submitting this form, I confirm that I have read, understood, and agreed to the event's Terms and Conditions, NTT DATA, Inc.'s 
                          <a
                            href="https://services.global.ntt/en-us/legal/terms-and-conditions"
                            target="_blank"
                            class="underline"
                            >Terms and Conditions</a
                          >
                          and
                          <a
                            href="https://services.global.ntt/en-us/legal/privacy-statement"
                            target="_blank"
                            class="underline"
                            >Privacy Policy</a
                          >. I authorize the sharing of my personal information between these <a
                            href="https://services.global.ntt/en-us/about-us/NTT-Ltd-direct-subsidiaries"
                            target="_blank"
                            class="underline"
                            >listed companies</a
                          > as well as their direct and indirect subsidiaries. I also consent to receiving personalized communications from NTT MSC Sdn. Bhd. about NTT DATA and selected partner products, services, offers, and events.
                          </label>
                      </div>
                      <div class="terms-conditions">
                        <p class="text-sm font-bold">Terms &amp; Conditions:</p>
                        <ol
                          class="text-sm leading-relaxed mt-2 mb-8"
                          style="list-style-type: lower-alpha; padding-left: 1rem"
                        >
                          <li>
                            NTT DATA's 2025 Chinese New Year Client &amp; Partner
                            Appreciation Dinner in Malaysia, is strictly
                            by-invitation only.
                          </li>
                          <li>
                            The organizer, NTT MSC Sdn. Bhd. (NTT DATA), reserves the right to reject registrations 
                            that do not represent valid organizations or are deemed inappropriate.
                          </li>
                          <li>
                            Please note that prior registration is required for this event. 
                            Seat confirmation is on a first-come, first-served basis.
                          </li>
                          <li>
                            A 'Seat Confirmation' email with your unique registration info 
                            will be sent to you from: NTT DATA Marcom
                            <a href="mailto:marcom@nttevents.my"
                              >&lt;marcom@nttevents.my&gt;</a>
                            upon NTT DATA's acceptance of your registration.
                          </li>
                          <li>
                            Registration deadline: January 8th, 2025 (Wednesday)
                          </li>
                          <li>
                            NTT DATA also reserves the right to decline onsite registrations deemed 
                            inappropriate and to deny entry to visitors without a 'Seat Confirmation' email 
                            or those with a 'Rejected Registration' email.
                          </li>
                          <li>
                            For any further assistance, please contact NTT DATA
                            marketing team at E:
                            <a href="mailto:ap.my.ask@global.ntt">
                              ap.my.ask@global.ntt
                            </a>
                          </li>
                        </ol>
                        <div class="text-center mt-6">
                          <button
                            class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="submit"
                            :disabled="isSuccess"
                          >
                            Register
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="px-12 py-12" v-else>
                    <p class="text-center success-msg">
                      Thank you for your registration.<br /><br />Once your
                      registration is APPROVED, a 'Seat Confirmation email' with
                      your unique registration info will be sent to your
                      registered business email address within 3 working days
                      from: NTT DATA Marcom
                      <a href="mailto:marcom@nttevents.my"
                        >&lt;marcom@nttevents.my&gt;</a
                      >
                      <br />
                      <br />
                      For any further assistance, please contact NTT DATA
                      marketing team at E:
                      <a href="mailto:ap.my.ask@global.ntt"
                        >ap.my.ask@global.ntt</a
                      >
                    </p>
                  </div>-->
                  <div class="px-12 py-12">
                    <p class="text-center success-msg">
                      Thank you for your interest in joining NTT DATA’s 2025 CNY Celebration Dinner in Malaysia.
                      <br /><br />Registration is now closed. For assistance, please contact your NTT DATA account manager or email us at 
                      <a href="mailto:ap.my.ask@global.ntt"
                        >ap.my.ask@global.ntt</a
                      >
                    </p>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import axios from "axios"
import topBanner from "@/assets/img/top-banner.jpg"

export default {
  data() {
    return {
      topBanner,
      isDropdownVisible: false,
      isRepDropdownVisible: false,
      countryCodes: [],
      repCountryCodes: [],
      form: {
        firstName: "",
        lastName: "",
        //fullName: "",
        repFirstName: "",
        repLastName: "",
        companyName: "",
        nature_of_business: "",
        jobTitle: "",
        email: "",
        countryCode: "60",
        repCountryCode: "60",
        mobileNumber: "",
        fundingAmount: "",
        interested: true,
        consent: false,
        vegetarian: null
      },
      isSuccess: false,
      errors: {
        mobileNumber: "",
        companyName: ""
      },
      isReadOnly: false // Control the readonly state
    }
  },
  methods: {
    async submit() {
      this.validateForm()
      if (Object.values(this.errors).some((error) => error)) {
        return
      }
      try {
        const res = await axios.post(
          process.env.VUE_APP_API_ENDPOINT + "/registration",
          {
            first_name: this.form.firstName,
            last_name: this.form.lastName,
            //full_name: this.form.fullName,
            company_name: this.form.companyName,
            email_address: this.form.email,
            phone_code: this.form.countryCode.toString(),
            contact_number: this.form.mobileNumber.toString(),
            rep_phone_code: this.form.repCountryCode.toString(),
            rep_contact_number: this.form.repMobileNumber,
            //nature_of_business: this.form.natureOfBusiness,
            job_title: this.form.jobTitle,
            //revenue: this.form.revenue === "below-other" ? this.form.otherRevenue : this.form.revenue,
            //programmes: this.form.programmes === "programmes-other" ? this.form.otherProgrammes: this.form.programmes,
            //funding_amount: this.form.fundingAmount,
            interested: this.form.interested,
            rep_first_name: this.form.repFirstName,
            rep_last_name: this.form.repLastName,
            rep_email: this.form.repEmail,
            event_id: 1,
            source: this.$route.query.source,
            consent: true
          }
        )
        if (res.status === 200) {
          this.isSuccess = true
        }
      } catch (error) {
        console.error("Error making API request:", error)
        alert(error.response?.data?.message || "An unexpected error occurred.")
      }
    },
    validateForm() {
      const regex = /^[^\s].*$/; // Ensures no leading whitespace

      // First Name
      if (!regex.test(this.form.firstName)) {
        this.errors.firstName = 'First name cannot start with a whitespace.';
        return false;
      } else {
        this.errors.firstName = ""; // Clear the error if valid
      }

      // Last Name
      if (!regex.test(this.form.lastName)) {
        this.errors.lastName = 'Last name cannot start with a whitespace.';
        return false;
      } else {
        this.errors.lastName = ""; // Clear the error if valid
      }

      // Job title
      if (!regex.test(this.form.jobTitle)) {
        this.errors.jobTitle = 'Job title cannot start with a whitespace.';
        return false;
      } else {
        this.errors.jobTitle = ""; // Clear the error if valid
      }

      // Company Name
      if (!regex.test(this.form.companyName)) {
        this.errors.companyName = 'Company Name cannot start with a whitespace.';
        return false;
      } else {
        this.errors.companyName = ""; // Clear the error if valid
      }

      // Email
      if (!regex.test(this.form.email)) {
        this.errors.email = 'Email cannot start with a whitespace.';
        return false;
      } else {
        this.errors.email = ""; // Clear the error if valid
      }

      // Mobile Number
      const mobileNumberRegex = /^[1-9]\d{6,}$/
      const isValidMobileNumber = mobileNumberRegex.test(this.form.mobileNumber)

      // Error message
      if (!isValidMobileNumber) {
        this.errors.mobileNumber = "Please enter a valid mobile number."
        return false;
      } else {
        this.errors.mobileNumber = ""
      }

      if (this.form.vegetarian === null) {
        this.errors.vegetarian = "Please select an option.";
        return false;
      } else {
        this.errors.vegetarian = "";
      }

      if (this.form.interested == "false1") {
        const { repFirstName, repLastName, repEmail, repMobileNumber } =
          this.form

        // Clear previous error messages
        this.errors = {
          repFirstName: "",
          repLastName: "",
          repEmail: "",
          repMobileNumber: ""
        }

        // Check if any required field is empty
        const isAnyFieldEmpty =
          !repFirstName || !repLastName || !repEmail || !repMobileNumber

        if (isAnyFieldEmpty) {
          // Display error message for each empty field
          if (!repFirstName)
            this.errors.repFirstName = "Please fill in all required fields."
          if (!repLastName)
            this.errors.repLastName = "Please fill in all required fields."
          if (!repEmail)
            this.errors.repEmail = "Please fill in all required fields."
          if (!repMobileNumber)
            this.errors.repMobileNumber = "Please fill in all required fields."
        } else {
          // Validate the mobile number
          const isValidRepMobileNumber = mobileNumberRegex.test(repMobileNumber)

          if (!isValidRepMobileNumber) {
            this.errors.repMobileNumber = "Please enter a valid mobile number."
          }
        }
      } else {
        // Clear previous error messages
        this.errors = {
          repFirstName: "",
          repLastName: "",
          repEmail: "",
          repMobileNumber: ""
        }
      }
    },
    async getFormData() {
      try {
        const response = await axios.get(
          process.env.VUE_APP_API_ENDPOINT +
            "/guest-detail-registration/" +
            this.$route.query.source
        )

        this.form.firstName = response.data.data.first_name
        this.form.lastName = response.data.data.last_name
        //this.form.fullName = response.data.data.full_name
        this.form.companyName = response.data.data.company_name

        const isInterested = response.data.data.interested

        this.isReadOnly = true

        if (isInterested !== null && isInterested !== "") {
          this.isSuccess = true
        }
      } catch (error) {
        console.error("Error fetching form data:", error)
        alert(
          "Invalid URL. Kindly make sure you open the URL from the invitation email."
        )
        this.isSuccess = true
      }

      // Funding Amount
      const fundingAmountRegex = /^[1-9]\d{0,}(\.\d{1,2})?$/;
      const isValidFundingAmount = fundingAmountRegex.test(
        this.form.fundingAmount
      );
      
      // Error message
      if (!isValidFundingAmount) {
        this.errors.fundingAmount = "Please enter a valid amount.";
      } else {
        this.errors.fundingAmount = "";
      }
    },
    async getCountryCode() {
      try {
        const res = await axios.get(
          process.env.VUE_APP_API_ENDPOINT + "/country-list"
        )
        this.countryCodes = res.data
        this.repCountryCodes = res.data
      } catch (error) {
        console.error("Error making API request:", error)
      }
    },
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible
      if (this.isDropdownVisible) {
        // Add event listener to close dropdown when clicking outside
        document.addEventListener("click", this.closeDropdownOnClickOutside)
      } else {
        // Remove event listener when dropdown is closed
        document.removeEventListener("click", this.closeDropdownOnClickOutside)
      }
    },
    closeDropdownOnClickOutside(event) {
      // Check if the clicked element is outside of the dropdown and trigger element
      if (
        this.$refs.countryCodeDropdown &&
        !this.$refs.countryCodeDropdown.contains(event.target) &&
        event.target !== this.$refs.countryCodeDropdown
      ) {
        this.isDropdownVisible = false
      }
    },
    repToggleDropdown() {
      this.isRepDropdownVisible = !this.isRepDropdownVisible
      if (this.isRepDropdownVisible) {
        // Add event listener to close dropdown when clicking outside
        document.addEventListener("click", this.closeRepDropdownOnClickOutside)
      } else {
        // Remove event listener when dropdown is closed
        document.removeEventListener(
          "click",
          this.closeRepDropdownOnClickOutside
        )
      }
    },
    closeRepDropdownOnClickOutside(event) {
      // Check if the clicked element is outside of the dropdown and trigger element
      if (
        this.$refs.repCountryCodeDropdown &&
        !this.$refs.repCountryCodeDropdown.contains(event.target) &&
        event.target !== this.$refs.repCountryCodeDropdown
      ) {
        this.isRepDropdownVisible = false
      }
    },
    selectCountry(countryCode) {
      this.form.countryCode = countryCode.phonecode
      this.isDropdownVisible = false
    },
    selectCountryRep(countryCode) {
      this.form.repCountryCode = countryCode.phonecode
      this.isRepDropdownVisible = false
    }
  },
  mounted() {
    //this.getFormData()
    this.getCountryCode()
  }
}
</script>

<style lang="sass" scoped>
.responsive-image
  border-top-left-radius: 0
  border-top-right-radius: 0

.form-container
  width: 100%
  max-width: 967px

.radio-button-group label
  display: flex
  align-items: center

.success-msg
  color: green

.error-msg
  color: red
  font-size: 0.75rem
  padding-top: 5px

ol li
  text-align: justify

button:disabled
  opacity: 0.5
  cursor: not-allowed

ol a
  text-decoration: underline

.country-code-dropdown
  width: 80px
  cursor: pointer

.custom-dropdown
  position: absolute
  z-index: 1
  top: 100%
  left: 0
  border: 1px solid #ccc
  background-color: #fff
  max-height: 300px
  min-width: 280px
  width: auto
  overflow-y: auto
  cursor: pointer
  .country-code-item
    font-size: 14px
    padding: 5px 8px
    &:hover
      background-color: #e4e4e7

.row
  gap: 1.5rem
  .col
    width: 100%

@media (max-width: 768px)
  h4
    font-size: 1.2rem
  p, input, .country-code-dropdown
    font-size: 0.8rem
  label, button
    font-size: 0.7rem
  .terms-conditions
    p
      font-size: 0.7rem
    ol
      font-size: 0.6rem
  .row
    flex-direction: column
    .col
      margin: 0
  .desktop-break
    display: none

@media (max-width: 1024px)
  .container
    max-width: 100% !important

@media (min-width: 768px)
  .responsive-image
    border-top-left-radius: 0.5rem
    border-top-right-radius: 0.5rem
</style>
