<template>
  <div class="bg-blueGray-800 h-screen">
    <main>
      <div class="container relative mx-auto">
        <div class="title">
          <h1 class="pb-4">
            CHECK-IN {{ errorMsg ? "FAILED" : "SUCCESSFUL" }}
          </h1>
        </div>
        <div class="user-detail-container">
          <table v-if="!errorMsg">
            <tr>
              <td class="label-column">Full Name</td>
              <td class="colon-column">:</td>
              <td class="value-column">{{ name }}</td>
            </tr>
            <!-- <tr>
              <td class="label-column">Rep. Name</td>
              <td class="colon-column">:</td>
              <td class="value-column">{{ rep_name }}</td>
            </tr> -->
            <tr>
              <td class="label-column">Company Name</td>
              <td class="colon-column">:</td>
              <td class="value-column">{{ companyName }}</td>
            </tr>
            <tr>
              <td class="label-column">Table No.</td>
              <td class="colon-column">:</td>
              <td class="value-column">{{ tableName }}</td>
            </tr>
            <!-- <tr>
              <td class="label-column">Category</td>
              <td class="colon-column">:</td>
              <td class="value-column">{{ category }}</td>
            </tr> -->
          </table>
          <div v-else>
            <p>{{ errorMsg }}</p>
          </div>
        </div>
        <div class="text-center mt-10">
          <router-link to="/check-in">
            <button
              class="bg-white text-blueGray-800 active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            >
              Scan another QR Code
            </button>
          </router-link>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios"
/*import printJS from "print-js";
import topBanner from "@/assets/img/a29035be.png";
import Speech from 'speak-tts'*/

export default {
  data() {
    return {
      id: this.$route.params.id,
      name: "",
      //rep_name: "",
      companyName: "",
      jobTitle: "",
      tableName: "",
      errorMsg: ""
    }
  },
  methods: {
    // call api to get
    async checkIn() {
      try {
        const res = await axios.post(
          process.env.VUE_APP_API_ENDPOINT + "/check-in-guest/" + this.id
        )
        if (res.status === 200) {
          this.name = res.data.full_name
          //this.rep_name = res.data.rep_full_name ?? '-';
          this.companyName = res.data.company_name
          this.tableName = res.data.table_name
          //this.category = res.data.delegate_name
        }

        /*const speech = new Speech();

        speech.init({
            volume: 1,
            lang: "en-US",
            rate: 1,
            pitch: 1,
            voice: 'Google US English',
        }).then(() => {
            speech.speak({
                text: 'Welcome Kobe Cheong, check-in successful!',
            });
        }).catch(e => {
            console.error("An error occurred during initialization:", e);
        });*/

        //printJS(topBanner, 'image');
        //printJS({printable: topBanner, type: 'image', header: '<center><table style="font-size:13px;font-family:calibri;text-align:center"><tr><td>Kobe Cheong</td></tr><tr><td>DIV Technology</td></tr><tr></center>'})
      } catch (error) {
        this.errorMsg =
          error.response.data.message ||
          "Something went wrong. Please try again."
      }
    }
  },
  mounted() {
    this.checkIn()
  }
}
</script>

<style lang="sass" scoped>
table
  width: 100%
  table-layout: fixed // Ensures fixed column widths
  border-collapse: collapse

td
  word-wrap: break-word // Allows long words to break and wrap to the next line
  white-space: normal // Allows the text to wrap onto multiple lines
  padding: 8px
  vertical-align: top

.label-column
  width: 38% // Adjust this percentage as needed to control label width
  font-weight: bold

.colon-column
  width: 2% // Ensures the colon has a fixed width
  text-align: center

.value-column
  width: 60% // Adjust this percentage to control value column width
</style>
